import React from 'react'
import ESC_CONTENT from '../../../components/shared/modals/esc-content'
import Layout from '../../../components/layout'

const ESC_FORTEGRA = () => (
  <Layout>
    <ESC_CONTENT region="FORTEGRA" />
  </Layout>
)
export default ESC_FORTEGRA
